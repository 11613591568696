import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEventType} from "@angular/common/http";
import {JiraTask} from "./jira-task";
import {timeout} from "rxjs/operators";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class JiraTasksService {

  @BlockUI() blockUI: NgBlockUI;
  api = "/"

  constructor(public http: HttpClient) {
    if (location.href.indexOf('4200') > 0) {
      this.api = 'http://localhost:8080/';
    } else {
      this.api = '/';
    }
  }

  save(jiraTask: JiraTask) {
    return this.http.post(this.api + 'create-issue', jiraTask, {responseType: 'text'}).pipe(timeout(15000));
  }

  getIssueTypes() {
    return this.http.get(this.api + 'issue-types');
  }

  getIssueEnvironments() {
    return this.http.get(this.api + 'issue-environments');
  }

  getInvestigatingTeam() {
    return this.http.get(this.api + 'issue-investigatingTeam');
  }

  public uploadAttachment(file: File, id) {
    const formData = new FormData();
    formData.append('file', file);
    this.blockUI.start();
    return this.http.post(this.api + 'upload-attachment/' + id, formData, {
      reportProgress: true,
      observe: 'events'
    }).map(event => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const message = 'Uploading ' + file.name + '. Progress ' + (event.loaded * 100 / event.total).toFixed(1) + '%';
          this.blockUI.update(message);
          break;
        case HttpEventType.Response:
          this.blockUI.stop();
          return event.body;
      }
    }, ((error: HttpErrorResponse) => {
      this.blockUI.stop();
    }));
  }
}
