export class JiraTask {
  title: string;
  description: string;
  userEmail: string;
  issueType: string;
  sendConfirmationMail: boolean;
  cellNumber :string;
  deviceMakeAndModel: string;
  merchantName: string;
  environment: string;
  investigatingTeam: string;
}
